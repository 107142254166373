import React from "react";
import "./Video.css";
import { Link, useParams } from "react-router-dom";
const Live = () => {
  const params = useParams();
  return (
    <div className="video">
      <div className="video-card-2 text-center widgets">
        <h3> Live Video Chat</h3>

        <p>
          Login with Megapersonals and enjoy with <br /> <b>Private Live Video Chat</b>{" "}
          your dating partner.
        </p>
        <Link to={`/live/chat/email/${params.id}`} className="lll_uu_ss_01 px-2">
          <img src="datas/images/skio-logo.png" alt="" />
          {/* <img src="/datas/images/sss_iii_aa.svg" alt="" className="bg-white"/> */}
          <span className="btn-text"> Login with Megapersonals</span>
        </Link>
      </div>
    </div>
  );
};

export default Live;
